import React from 'react'

export default function Warning() {
  return (
    <div className='m-auto'>
      
    ....
      
      </div>
  )
}
